import React, { useEffect, useRef } from "react"
import { navigate, Link } from "gatsby"
import { isEDMode, getNested } from '@src/helper';
import Layout from '@components/structure/Layout.jsx';
// Utility imports
import SEO from '@components/utility/SEO.jsx';

export const Head = () => (
	<>
        <meta name="robots" content="noindex"></meta>
	</>
)

const ResubscribeConfirmed = ({location}) => {

    if (isEDMode) {
        location.state = {
            values: {
                email: [<span className="variable-content">name@domain.com</span>],
                unsubscribeTreatment: true,
                unsubscribeDiseaseAwareness: true,
                unsubscribeCorporate: true
            } 
        }
    }
    const locationState = useRef(location?.state?.values)
    useEffect(()=>{
        //gatsby isn't mapping location.state correctly on build so we need to do a nested check to see if email exists if not we redirect
        if(!isEDMode && !getNested(location,'state', 'values', 'email')) {
            
            navigate('/');
        }
    },[])

    return (
        <Layout pageClassName="page-resubscribe-confirmation">
		<SEO title='You Have Successfully Resubscribed | Aurinia Alliance&reg;' description='' />
		<div className="wrapper resubscribe-confirmation-content">
            <h1>Welcome back!</h1>
            <h2 className="blue">We're happy to see you again</h2> 
            <p>{locationState?.current?.email}, you've successfully resubscribed to marketing communications from Aurinia Alliance.</p>
            <Link to="/" className="btn btn--purple btn--resubscribe">RETURN TO HOME</Link>
		</div>
		
	</Layout>

    )
}

export default ResubscribeConfirmed
